import React from 'react';
import * as styles from './location.module.scss';

const locationClassNames = {
  north: styles.locationColorGreen,
  west: styles.locationColorRed,
  east: styles.locationColorPurple,
  south: styles.locationColorBlue
};

const locationNames = {
  north: 'N',
  west: 'W',
  east: 'E',
  south: 'S'
};

const Location = ({ location, ...props }) => (
  <span className={styles.location + ' ' + locationClassNames[location]}>{locationNames[location]}</span>
);

export default Location;