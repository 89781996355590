import React from 'react';
import Imgix from 'react-imgix';

type ImageProps = {
  src: string;
  width: number;
  height: number;
  params?: any;
  sizes?: any;
  alt?: string;
  className?: string;
  htmlAttributes?: React.HTMLAttributes<HTMLElement>;
}

export const IMGIX_URL = 'https://todo-aland.imgix.net/';

export function CustomImage({ src, width, height, params, sizes, alt, className, htmlAttributes }: ImageProps) {
  return (
    <Imgix
      className={className}
      src={IMGIX_URL + src}
      sizes={sizes}
      width={width}
      height={height}
      imgixParams={{
        auto: 'format,compress',
        fit: 'crop',
        cs: 'srgb',
        ...params
      }}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes'
      }}
      htmlAttributes={{
        ...htmlAttributes,
        src: IMGIX_URL + `${src}?auto=format,compress&${width ? `&w=${width}` : ''}${height ? `&h=${height}` : ''}`,
        draggable: false,
        alt: alt,
      }}></Imgix>
  );
}