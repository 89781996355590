import firebase from 'gatsby-plugin-firebase';
import { firstValueFrom } from 'rxjs';
import { EventType } from '../models/events.model';
import { getUserService } from './user.service';

export class EventService {
  async logEvent(type: EventType, payload: any) {
    const isAuthenticated = firebase.auth().currentUser != null;
    const userId = isAuthenticated ? firebase.auth().currentUser.uid : null;
    let user = null;

    if(isAuthenticated) {
      const userService = getUserService();
      userService.init();
      user = userService.user ? userService.user : await firstValueFrom(userService.user$);
      user = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        displayName: user.displayName,
        country: user.country,
        birthYear: user.birthYear,
        address: user.address
      };
    }

    firebase.firestore().collection('events').add({
      eventType: type,
      timestamp: new Date(),
      authenticated: isAuthenticated,
      userId: userId,
      user: user,
      payload: payload,
    });
  }
}