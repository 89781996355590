import firebase from 'gatsby-plugin-firebase';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventService } from './events.service';
import { getUserService } from './user.service';

class ActivityService {
  favouriteActivities: string[] = [];
  favouriteActivities$: Subject<string[]> = new BehaviorSubject([]);
  unsubscribe$: Subject<void> = new Subject();
  initialized: boolean = false;
  activitySearchIndex: any = null;

  init() {
    if(this.initialized) return;
    this.initialized = true;

    this.subscribeToUser();
  }

  subscribeToUser() {
    const userService = getUserService();
    userService.init();
    userService.user$.subscribe(user => {
      if(user) {
        const fav = user.favouriteActivities || [];
        this.favouriteActivities$.next(fav);
        this.favouriteActivities = fav;
      } else {
        this.favouriteActivities = [];
        this.favouriteActivities$.next([]);
      }
    });
  }

  setFavourite(activity: string, favourite: boolean) {
    if(!firebase.auth().currentUser) {
      return;
    }

    let newFavouriteActivities = [];
    if(!this.favouriteActivities.includes(activity)) {
      if(favourite) {
        newFavouriteActivities = [...this.favouriteActivities, activity];
      }
    } else {
      if(!favourite) {
        newFavouriteActivities = this.favouriteActivities.filter(item => item !== activity);
      }
    }
    firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update('favouriteActivities', newFavouriteActivities);

    // Track event
    const eventService = new EventService();
    if(favourite === true) {
      eventService.logEvent(
        'favourite_activity',
        {
          activityId: activity
        }
      );
    } else {
      eventService.logEvent(
        'unfavourite_activity',
        {
          activityId: activity,
        }
      );
    }
  }

  async getActivitySearchIndex(lang: string) {
    if(!this.activitySearchIndex) {
      const result = await fetch(`/static/activities-${lang}.index.json`).then(res => res.json());
      this.activitySearchIndex = result;
      return result;
    } else {
      return this.activitySearchIndex;
    }
  }
}

let activityServiceInstance = new ActivityService();
export function getActivityService() {
  return activityServiceInstance;
}