import { BehaviorSubject, Subject } from 'rxjs';
import { UserModel } from '../models/user.model';
import { AuthService } from './auth.service';
import firebase from 'gatsby-plugin-firebase';
import { take } from 'rxjs/operators';

class UserService {
  user: UserModel;
  user$: Subject<UserModel> = new BehaviorSubject(null);
  unsubscribe$: Subject<void> = new Subject();
  initialized: boolean = false;

  init() {
    if(this.initialized) return;
    this.initialized = true;

    if(new AuthService().isLoggedIn()) {
      this.subscribeToUser(firebase.auth().currentUser);
    }

    firebase.auth().onAuthStateChanged(user => {
      if(user) {
        this.subscribeToUser(user);
      } else {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
    })
  }

  subscribeToUser(user: any) {
    this.unsubscribe$ = new Subject();
    const unsubscribeFirebase = firebase.firestore().collection('users').doc(user.uid).onSnapshot((changes) => {
      this.unsubscribe$.pipe(take(1)).subscribe(() => unsubscribeFirebase());
      const userData = changes.data();
      this.user = userData as UserModel;
      this.user$.next(this.user);
    });
  }
}

let userServiceInstance = new UserService();
export function getUserService() {
  return userServiceInstance;
}